import axios from 'axios'
import Vue from 'vue';
import { Notify } from 'vant';
import { filterEmptyData } from '@/utils/url'
import { getStore, setStore } from '@/config/mUtils'
import store from '../store'
import router from '../router'
import 'vant/lib/notify/style'

Vue.use(Notify);

const serviceConfig = {
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 5000,
  responseType: 'json',
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-type': 'application/json;charset=utf-8',
    'Authorization': store.getters.token
  }
};

const service = axios.create(serviceConfig);

service.interceptors.request.use(
  config => {
    config.params && (config.params = filterEmptyData(config.params))
    config.url = encodeURI(config.url)
    return config
  },
  error => {
    return Promise.reject(error)
  }
);

service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status === 200 || response.status === 201) {
      return res
    } else {
      Promise.reject(new Error(response.status || 'Error'))
    }
  },
  error => {
    const { status } = error.response;
    const { message } = error.response.data;
    handleHttpStatus(status, message)
    return Promise.reject(error)
  }
);

const handleHttpStatus = (status, message = '') => {
  switch (status) {
    case 401:
      Notify({ type: 'danger', message: '您还没有登录，请先登录！' });
      setStore('jt-device-mobile-token', '')
      setStore('jt-device-mobile-user', '')
      router.push({
        path: '/login'
      })
      // window.location.href = 'https://h5.device.jiot.link/#/login'
      break;
    case 400:
      Notify({ type: 'danger', message: message });
      break;
  }
}

export default service
