/**
 * Created by wanghao on 2020/2/8
 */
import wx from 'weixin-js-sdk'
import Vue from 'vue';
import { Notify } from 'vant';
Vue.use(Notify);

/**
 * 微信鉴权验证
 * @param res
 */
export const wxLogin = (res) => {

  wx.config({
    debug: false, // 调试模式
    appId: res.appId, // 必填，公众号的唯一标识
    timestamp: res.timestamp, // 必填，生成签名的时间戳
    nonceStr: res.nonceStr, // 必填， 生成签名的随机串
    signature: res.signature, // 必填， 签名
    jsApiList: ['scanQRCode'] // 必填，需要使用的微信JS接口列表
  })

  /**
   * config信息验证成功后会执行ready方法，所有接口调用都必须在config接口获得结果之后。
   */
  wx.ready(() => {
    wx.checkJsApi({ // 判断当前客户端版本是否支持指定JS接口
      jsApiList: [
        'scanQRCode'
      ],
      success: (res) => { 
        if (res.checkResult.scanQRCode === true) {
          // wx.scanQRCode({ // 微信扫一扫接口
          //   desc: 'scanQRCode desc',
          //   needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          //   scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
          //   success: function (res) {
          //     const getCode = res.resultStr // 当needResult 为 1 时，扫码返回的结果
          //     return getCode
          //   }
          // })
        } else {
          Notify({ type: 'danger', message: '抱歉，当前客户端版本不支持扫一扫！' });
        }
      },
      fail: () => { // 检测getNetworkType该功能失败时处理
        Notify({ type: 'danger', message: 'fail:' + res });
      }
    })
  })

  /**
   * 处理失败config验证
   */
  wx.error(function (res) {
    Notify({ type: 'danger', message: '配置验证失败: ' + res.errMsg });
  })
}

/**
 * 微信扫码
 */
export const scanCode = (fn) => {
  wx.scanQRCode({
    // 默认为0，扫描结果由微信处理，1则直接返回扫描结果
    needResult: 1,
    desc: 'scanQRCode desc',
    success(res) {
      fn(res.resultStr)
    },
  });
}
